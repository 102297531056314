import request from '@/utils/request'

export function getPageList(data) {
  return request({
    url: 'attachment/getPageList',
    method: 'post',
    data,
  })
}

