<template>
  <div style="width: 100%">
    <el-table border size="mini" :data="datas" style="width: 100%">
      <el-table-column prop="Name" fixed="left" label="资料名称" min-width="150">
      </el-table-column>
      <el-table-column prop="Check.Code" label="测试编号" min-width="150">
      </el-table-column>
      <el-table-column prop="Check.Member.RealName" label="客户姓名" min-width="120">
      </el-table-column>
      <el-table-column prop="Type" label="资料类别" min-width="120" header-align="center">
        <template slot-scope="scope">
          {{ format.attachmentTypeFormat(scope.row.Type) }}
        </template>
      </el-table-column>
      <el-table-column :prop="Path" label="下载预览" :min-width="150">
        <template slot-scope="scope">
           <viewer v-if="format.estimateIsImage(scope.row.Path)">
              <img
                v-if="scope.row.Path"
                :src="baseUrl + scope.row.Path"
                :key="scope.row.Path"
                style="height: 40px"
              />
            </viewer>
            <span v-else style="font-size: 14px"
              ><a :href="baseUrl + scope.row.Path" target="_blank">
                点此下载</a
              ></span
            >
        </template>
      </el-table-column>
      <el-table-column
        prop="InsertTime"
        label="上传时间"
        width="150"
        sortable
      >
        <template slot-scope="scope">
          {{ format.dateTimeFormat(scope.row.InsertTime) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :page-sizes="pageSizeList"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :current-page.sync="pageIndex"
    ></el-pagination>
  </div>
</template>
<script>
import { getPageList } from "@/api/attachment.js";
export default {
  components: {},
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      default: "",
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.pageIndex = 1;
        this.getData();
      },
    },
  },
  data() {
    return {
      datas: [],
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      pageSizeList: [10, 20, 30, 40, 50],
    };
  },

  methods: {
    getData() {
      this.filter.pageIndex = this.pageIndex;
      this.filter.pageSize = this.pageSize;
      getPageList(this.filter).then((res) => {
                this.datas = res.Items;
            this.total = res.Total || res.Items.length;
      });
    },
    pageSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },
    pageCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.getData();
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.status {
  align-self: flex-end;
  font-size: 12px;
  font-weight: bold;
  color: #ff862f;
}
.statusdesc {
  align-self: flex-end;
  font-size: 12px;
  color: #797878;
}
</style>